.finwise-calc {
  margin: 3rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .finwise-calc__inner {

    .mdc-select,
    .mdc-text-field {
      height: 5rem;
      background-color: rgba(255, 255, 255, 0.15);

      border-radius: 20px;
      border: solid 1px rgba(255, 255, 255, 0.55);
      margin-bottom: 1rem;

      @include desktop-and-tablet{
        margin-right: 1rem;
      }

      .mdc-floating-label {
        color: $webiny-pb-theme-primary;
      }
    }

    .mdc-select {
      select {
        height: 5rem;

        color: $webiny-pb-theme-primary;
        font-size: 1.4rem;
        line-height: 2.75rem;
      }
      &__dropdown-icon {
        background: url(../../components/assets/select-arr.svg) no-repeat center center;
        bottom: 20px;
      }
      &--focused {
        border: solid 1px rgba(255, 255, 255, 0.75);

        .mdc-floating-label {
          color: #ffffff;
        }
        .mdc-line-ripple {
          background-color: $webiny-pb-theme-secondary;
        }
      }
      &:hover {
        .mdc-floating-label {
          color: #ffffff;
        }
        .mdc-line-ripple {
          background-color: #ffffff;
        }
      }

      &.input-currency {
        margin-right: 0;

        @include desktop-and-tablet{
          border-radius: 0 20px 20px 0;
          border-left: 0;
        }
      }
    }

    .mdc-text-field {

      &__input {
        color: #ffffff;
        font-size: 2rem;
        line-height: 2.75rem;
        caret-color: $webiny-pb-theme-secondary;
      }
      &--focused {
        .mdc-floating-label {
          color: #ffffff;
        }
        .mdc-line-ripple {
          background-color: $webiny-pb-theme-secondary;
        }
      }
      &:hover {
        .mdc-floating-label {
          color: #ffffff;
        }
        .mdc-line-ripple {
          background-color: #ffffff;
        }
      }

      &.input-amount {
        margin-right: 0;

        @include desktop-and-tablet{
          border-radius: 20px 0 0 20px;
        }
      }
    }

    .fee {
      width: 100%;
      text-align: center;
      font-size: 4rem;
      padding: 2rem 0 2rem;
      margin: 2rem 0 2rem;

      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 20px;
      border: solid 1px rgba(255, 255, 255, 0.3);

      h3 {
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 1.1rem;
        color: $webiny-pb-theme-primary;
      }

      .fee--amount {
        font-size: 160%;
        color: $webiny-pb-theme-secondary;

        strong {
          font-weight: 800;
        }
      }
      .fee--currency {
        margin-left: 0.8rem;
        text-transform: uppercase;

        color: $webiny-pb-theme-primary;
      }
    }

    .total {
      width: 100%;
      text-align: center;
      font-size: 4rem;
      padding: 2rem 0 2rem;
      margin: 2rem 0 2rem;

      @include desktop-and-tablet{
        margin-right: 1rem;
      }

      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 20px;
      border: solid 1px rgba(255, 255, 255, 0.3);

      h3 {
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 1.1rem;
        color: $webiny-pb-theme-primary;
      }

      .total--amount {
        font-size: 160%;
        color: $webiny-pb-theme-secondary;

        strong {
          font-weight: 800;
        }
      }
      .total--currency {
        margin-left: 0.8rem;
        text-transform: uppercase;

        color: $webiny-pb-theme-primary;
      }
    }

    .country-list {
      margin-top: 1rem;
      line-height: 1rem;
      font-size: 0.8rem;
      color: $webiny-pb-theme-primary;

      max-width: 90%;
    }
  }
}
