.gmap-fullwidth,
.branches-fullwidth {
  width: 100%;
  min-height: 300px;
  position: relative;
  margin-bottom: 75px;
  // border-bottom: solid 7px var(--webiny-pb-theme-primary, #23325b);

  .map-box__inner {
    width: 100%;
    height: 500px;
    display: block;
  }
}

.branches-fullwidth {
  min-height: 60vh;

  .map-box__inner {
    height: 60vh;

    .map-marker {
      height: 0.8rem;
      width: 0.8rem;

      box-shadow: 0px 0 1px 1px #d73f1c57;
    }
  }

}
