.webiny-pb-layout-block-container{
  @include mobile{
    background-size: cover !important;
  }
}

.webiny-pb-layout-block{
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-self: flex-start;

  @include mobile{
    width: 100% !important;
    // flex: 0 0 auto !important; // removed because Content #2
  }
}

.webiny-pb-layout-row{
  position: relative;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;

  @include desktop-and-tablet{
    // flex: 100% 1; // removed because Content #2
    flex-direction: row;
  }

  @include mobile{
    max-width: 100% !important;
    //width: 100% !important; // added for Content #6
    width: auto !important; // set to auto for CTA #9
    flex: 0 auto !important;
  }
}

.webiny-pb-layout-column{
  box-sizing: border-box;
  text-align: center;
  display: flex;
  position: relative;
  flex: auto 0;
  flex-direction: column;

  @include mobile{
    width: auto !important;
  }

  @include desktop-and-tablet{
    text-align: left;
    width: auto;
  }
}
