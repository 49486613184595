.webiny-pb-section-footer {
  background-color: var(--webiny-theme-color-background, #eaecec);
  @include linearGradient($webiny-pb-theme-background, darken($webiny-pb-theme-background, 20%));
  border-top: solid 14px var(--webiny-pb-theme-secondary, #d73f1c);
  color: $webiny-pb-theme-primary;
  font-size: 0.9rem;

  padding-top: 2rem;
  min-height: 8rem;
  position: absolute;
  bottom: 0;
  width: 100%;

  &__wrapper{
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    @include desktop-and-tablet{
      justify-content: space-between;
      flex-direction: row;
    }
  }

  &__logo {
    flex: 1;
    align-items: center;
    display: flex;
    a{
      display: inline-block;
      line-height: 80%;
      img{
        max-height: 2.2rem;
        margin-right: 1.4rem;
      }
    }

    margin-top: 1.5rem;

    @include mobile{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;


      a img{
        max-height: 4rem;
        margin: 0;
      }
    }
  }

  &__links {
    flex: 10;
    align-items: center;
    display: flex;
    flex-direction: column;
    line-height: 1.4rem;

    margin-top: 1.5rem;

    p{
      width: 100%;
    }

    a{
      display: inline-block;
      line-height: 80%;
      text-decoration: underline;
      margin: 0 0.4rem;
      transition: 0.3s linear;

      &:first-child {
        margin-left: 0;
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
    @include mobile{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        text-align: center;
        margin-bottom: 0.6rem;
      }

    }
  }

  &__copy {
    flex: 1;
    align-items: center;
    display: flex;

    margin-top: 1.5rem;

    a{
      display: inline-block;
      line-height: 80%;
      background: url(../../components/assets/hradecon.svg) no-repeat center right;
      background-size: contain;
      padding-right: 6.6rem;
      line-height: 1.2rem;

      transition: 0.3s filter linear, 0.3s -webkit-filter linear;

      &:hover,
      &:focus {
        text-decoration: none;
        filter: invert(29%) sepia(57%) saturate(2481%) hue-rotate(352deg) brightness(95%) contrast(90%);
      }
    }
    @include mobile{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      margin-bottom: 15px;
    }
  }

  &__social {
    text-align: right;
    a{
      display: inline-block;
      svg{
        height: 25px;
        margin-left: 10px;
        opacity: .5;
        transition: opacity .3s;
        color: var(--webiny-theme-color-on-background, #131313);
        &:hover{
          opacity: 1;
        }
      }
    }
    @include desktop-and-tablet{
      flex: 1;
    }
    @include mobile{
      height: 25px;
      padding-bottom: 10px;
    }
  }
}

// adjust some styles in case when we render the full layout inside the page editor
.webiny-pb-editor-content-preview{
  .webiny-pb-section-footer {
    padding: 0 10px;
    box-sizing: border-box;
    position: relative;
  }
}
