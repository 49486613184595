.webiny-pb-page-element-page-list{
  &__items{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

  }
  &__navigation{
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: 1rem auto 0;

    a{
      font-size: 1rem;
      display: flex;
      align-content: center;
      cursor: pointer;
      svg{
        height: 18px;
        display: block;
      }
    }
  }
  &__icon{
    min-width: 40px;
    min-height: 40px;
    background-size:100% 100%;
    background: url(../../components/assets/news-icon.svg) no-repeat center center;
    margin-right: 0.8rem;
    flex: 0 0 40px;
  }
  &__item{
    width: 280px;
    flex: 1 1 280px;
    margin-bottom: 35px;
    &:hover{
      text-decoration: none;
    }

    @include desktop{
      flex: 0 0 280px;
    }

  }
  &__media{
    width: 100%;
    height: 180px;
    background-size: cover;
  }
  &__title{
    &:hover{
      text-decoration: underline;
    }
  }

  &.title-page {
    min-height: 10rem;

    .webiny-pb-page-element-page-list__heading {
      display: flex;
      align-items: flex-start;
      margin:auto;
    }

    .webiny-pb-typography-h3 {
      font-size: 1.6 * $scale * 1rem;
      font-weight: 700;
      color: $webiny-pb-theme-primary;
      margin-bottom: 0.4rem;
      margin-top: 0;
      text-align: left;
    }

    .webiny-pb-page-element-page-list__date {
      color: #044c96;
      margin-bottom: 0.6rem;
      text-align: left;
    }

    .webiny-pb-page-element-page-list__item {
      background: $webiny-pb-theme-background;
      padding: 1.2rem;
      margin-bottom: 0;

      @include mobile{
        margin-bottom: 1rem;
      }
    }
  }
}
