@keyframes slide-in {
  100% { transform: translateX(0%); }
}

@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% {transform: translateX(200%);}
}

.webiny-pb-section-header-spacer{
  height: 0px;
  background-color: transparent;
  width: 100%;
}

.webiny-pb-section-header {
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: rgba(34, 45, 57, 0.05) 0px 0px 2px 1px;
  width: 100%;
  height: 40px;
  padding-top: 10px;

  &__wrapper{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
  }
}

// desktop header
@include desktop-and-tablet{
  .webiny-pb-section-header {
    padding: 10px 0 5px 0;

    &__logo{
      flex: 1;
      margin-left: 0;
      img{
        max-height: 30px;
      }
    }

    &__site-name{
      font-size: 1.1rem;
    }

    &__navigation {
      color: var(--webiny-theme-color-primary, #ee896b);
      font-family: var(--webiny-theme-typography-primary-font-family, "Poppins, sans-serif");
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      flex: 1 1 100%;

      a {
        color: var(--webiny-theme-color-primary, #ee896b);
        text-decoration: none;
        padding: 10px;
      }

      ul{
        display: flex;
        justify-content: flex-end;

        li{
          display: inline-block;
          position: relative;
          margin-left: 25px;
          font-size: 1rem;
          line-height: 1rem;
          font-weight: 400;
          cursor: pointer;
          transition: background-color .2s;
          ul{
            display: none;
          }
          &:hover{
            a{
              background-color: rgba($webiny-pb-theme-background, 0.6);
              border-radius: var(--webiny-theme-border-radius, 2px);
              color: $webiny-pb-theme-secondary;
            }
            ul{
              display: block;
              position: absolute;
              top: 35px;
              left: 0;
              width: 150px;
              background-color: var(--webiny-theme-color-background, #eaecec);
              li{
                padding: 10px;
                margin: 0;
                width: 100%;
                box-sizing: border-box;
              }
            }
          }
        }
      }
    }
  }
}

// mobile header
@include mobile{
  .webiny-pb-section-header {
    &__mobile-icon{
      position: fixed;
      top: 18px;
      right: 25px;
      z-index: 12;
    }

    &__mobile-site-name{
      position: fixed;
      width: 100%;
      right: -25px;
      top: 28px;
      z-index: 12;
      a{
        font-size: 1.2rem;
        color: var(--webiny-theme-color-text-primary, rgb(10, 10, 10));
        text-transform: uppercase;
        font-weight: 700;
      }
    }

    &__mobile-overlay{
      opacity: 0;
      transition: opacity .25s ease-in-out;
      background-color: var(--webiny-theme-color-on-background, #131313);
      &--active{
        opacity: 0.5;
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 10;
        overflow: hidden;
        top:0;
        left: 0;
      }
    }

    &__logo{
      margin-left: 25px;
      width: 50%;
      img{
        max-height: 30px;
      }
    }

    &__navigation {
      color: var(--webiny-theme-color-primary, #ee896b);
      font-family: var(--webiny-theme-typography-primary-font-family, "Poppins, sans-serif");
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      animation: slide-out 0.5s forwards;
      animation-timing-function: ease-in-out;
      position: fixed;
      width: 250px;
      right: 0;
      height: 100%;
      background: var(--webiny-theme-color-background, #fafbfb);
      top: 0;
      z-index: 11;
      transform: translateX(200%);
      &--mobile-active{
        animation: slide-in 0.5s forwards;
        display: block;
      }

      a {
        color: var(--webiny-theme-color-primary, #ee896b);
        text-decoration: none;
      }

      ul{
        font-size: 1.2rem;
        margin-top: 75px;
        margin-left: 35px;
        box-sizing: border-box;
        font-weight: 400;
        text-transform: uppercase;
        li{
          margin-bottom: 15px;
          ul{
            margin: 10px 0 0 25px;
          }
        }
      }

    }

  }
}

// adjust some styles in case when we render the full layout inside the page editor
.webiny-pb-editor-content-preview{
  .webiny-pb-section-header {
    position: absolute;
    padding: 10px;
    box-sizing: border-box;
    z-index: 0;
    height: 50px;
  }
  .webiny-pb-section-header-spacer{
    height: 5px;
    background-color: white;
  }
}
